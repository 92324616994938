import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dcb861d0 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _48f365cb = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _796f4efe = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _7a942956 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _759b89f6 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _5c900d94 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _710a0649 = () => interopDefault(import('../pages/dashboard-new/index.vue' /* webpackChunkName: "pages/dashboard-new/index" */))
const _39c593de = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _b1d71040 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _290cd53e = () => interopDefault(import('../pages/financial-metrics/index.vue' /* webpackChunkName: "pages/financial-metrics/index" */))
const _507032d7 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _6508251a = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _d462dc16 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _7c8f68f0 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _2e2e7a70 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49837be1 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _8208cae2 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _2e4b9c98 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _81bd5186 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _5ce909c1 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _1a2271c0 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _4b7d0572 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _df2b4c72 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _c8af60e6 = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _2d6544e2 = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _cb7a4cbc = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _f91d6c7c = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _4cf00748 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _1694391e = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _ddf46ad8 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _ec27d3d2 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _005345fe = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _0470b6b2 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _a664239e = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _54009daf = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _ee0173e2 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _231845fe = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _27c93097 = () => interopDefault(import('../pages/dashboard/productivity-metrics/index.vue' /* webpackChunkName: "pages/dashboard/productivity-metrics/index" */))
const _4e32c99d = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _e733c0e2 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _4c896a85 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _d2f8c7da = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _13d47105 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _7816dbb0 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _38b19540 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _18b25b3a = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _70feae0e = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _7aa336e2 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _d253fa50 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _f0e75298 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _5ff4fb58 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _71f0c1ae = () => interopDefault(import('../pages/dashboard-new/components/danger-metrics.vue' /* webpackChunkName: "pages/dashboard-new/components/danger-metrics" */))
const _3f9d5ec0 = () => interopDefault(import('../pages/profiles/request-update/request-update-mixin.js' /* webpackChunkName: "pages/profiles/request-update/request-update-mixin" */))
const _673efafe = () => interopDefault(import('../pages/profiles/request-update/_profile.vue' /* webpackChunkName: "pages/profiles/request-update/_profile" */))
const _5451107a = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _3033d156 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _ad60b94c = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _e89f1598 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _ca3c6d8e = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _cadb625e = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _41ff9949 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _153acd2c = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _a06390d0 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _62fb761a = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _746c5532 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _2b2c81d0 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _ca4a52be = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _70997312 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _1f411fb1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _dcb861d0,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _48f365cb,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _796f4efe,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _7a942956,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _759b89f6,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _5c900d94,
    name: "dashboard"
  }, {
    path: "/dashboard-new",
    component: _710a0649,
    name: "dashboard-new"
  }, {
    path: "/faqs",
    component: _39c593de,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _b1d71040,
    name: "feedback-funnel"
  }, {
    path: "/financial-metrics",
    component: _290cd53e,
    name: "financial-metrics"
  }, {
    path: "/ht-alerts",
    component: _507032d7,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _6508251a,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _d462dc16,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _7c8f68f0,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _2e2e7a70,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _49837be1,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _8208cae2,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _2e4b9c98,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _81bd5186,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _5ce909c1,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _1a2271c0,
    name: "my-team"
  }, {
    path: "/news",
    component: _4b7d0572,
    name: "news"
  }, {
    path: "/notifications",
    component: _df2b4c72,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _c8af60e6,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _2d6544e2,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _cb7a4cbc,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _f91d6c7c,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _4cf00748,
    name: "profile"
  }, {
    path: "/profiles",
    component: _1694391e,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _ddf46ad8,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _ec27d3d2,
    name: "settings"
  }, {
    path: "/stats",
    component: _005345fe,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _0470b6b2,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _a664239e,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _54009daf,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _ee0173e2,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _231845fe,
    name: "wink-replies"
  }, {
    path: "/dashboard/productivity-metrics",
    component: _27c93097,
    name: "dashboard-productivity-metrics"
  }, {
    path: "/manuals/new",
    component: _4e32c99d,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _e733c0e2,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _4c896a85,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _d2f8c7da,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _13d47105,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _7816dbb0,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _38b19540,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _18b25b3a,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _70feae0e,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _7aa336e2,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _d253fa50,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _f0e75298,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _5ff4fb58,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/dashboard-new/components/danger-metrics",
    component: _71f0c1ae,
    name: "dashboard-new-components-danger-metrics"
  }, {
    path: "/profiles/request-update/request-update-mixin",
    component: _3f9d5ec0,
    name: "profiles-request-update-request-update-mixin"
  }, {
    path: "/profiles/request-update/:profile?",
    component: _673efafe,
    name: "profiles-request-update-profile"
  }, {
    path: "/account-requests/:request?",
    component: _5451107a,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _3033d156,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _ad60b94c,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _e89f1598,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _ca3c6d8e,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _cadb625e,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _41ff9949,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _153acd2c,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _a06390d0,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _62fb761a,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _746c5532,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _2b2c81d0,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _ca4a52be,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _70997312,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _1f411fb1,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
